$pointofsalecost-lynx-screen-blue: #27aae1;
$pointofsalecost-progress: #aaa;
$pointofsalecost-singing-blue: #0375a3;

:export {
    lynxScreenBlue: $pointofsalecost-lynx-screen-blue;
    progressColor: $pointofsalecost-progress;
    singingBlue: $pointofsalecost-singing-blue;
    primaryColor:$pointofsalecost-singing-blue;
}
